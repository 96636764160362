export default defineNuxtRouteMiddleware(() => {
	if (!process.client) {
		return;
	}

	const isGuest = useIsGuest();

	if (isGuest.value === true) {
		return navigateTo("/", { external: true });
	}
});
